<template>
    <div class="section-content pt-1 pr-0" :class="{'pt-5 pr-4' : facilityID}">
        <v-row v-if="level == 'Property'" class="pt-0">
            <v-col style="max-width: 350px;" class="pt-0">
                <v-autocomplete
                id="facility"
                rounded
                :items="facilityList"
                item-text="name"
                item-value="id"
                v-validate="'required'"
                v-model.lazy="property_id"
                data-vv-scope="gate"
                data-vv-name="facility"
                data-vv-as="Property"
                outlined
                dense
                single-line
                background-color="white"
                label="Select Property"
                :error-messages="errors.collect('gate.facility')"
                :hide-details="!errors.collect('gate.facility').length"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-divider class="pb-2" v-if="level == 'Property'"></v-divider>

        <div class="primary-section-content" :class="{'pt-4' : level !== 'Corporate' && !facilityID}">

            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

            <v-row class="mt-2 mb-6 px-4" v-if="level == 'Corporate'">
                <v-col cols="9" class="ma-0 pa-0 hb-text-light">
                Manage what fees are available
                </v-col>
                <v-col cols="3" class="ma-0 pa-0 text-right">
                <a class="hb-link" @click="addItem()">+ Create Rent</a>
                </v-col>
            </v-row>
            <v-card elevation="1">
                <v-data-table
                    :headers="headers"
                    :items="rentList"
                    disable-pagination
                    hide-default-footer
                    class="hb-data-table"
                >
                    <template v-slot:item.name="{ item }">
                        {{item.name}}<br />
                        <span class="hb-text-light">{{item.description}}</span>
                    </template>
                    <template v-slot:item.taxable="{ item }">{{ item.taxable ? 'Yes' : 'No' }}</template>
                    <template v-slot:item.in="{ item }">{{ item.prorate ? 'Yes' : 'No' }}</template>
                    <template v-slot:item.out="{ item }">{{ item.prorate_out ? 'Yes' : 'No' }}</template>
                    <template v-slot:item.actions="{ item }">
                        <hb-menu
                            options
                            align-right
                        >
                            <v-list>
                                <v-list-item @click="editItem(item)">
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="deleteItem(item)">
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                </v-data-table>
            </v-card>

        </div>

        <edit-product v-model="showAdd" v-if="showAdd" productType="rent" :facilityID="facilityID || property_id" :productLevel="level" :selected="selected" @showSuccess="showSuccessStatus" @close="closeWindow" @refetch="fetchData" />


        <!-- <edit-rent v-if="showAdd" size="lg" slot="body" :selected="selected" @showSuccess="showSuccessStatus" @close="closeWindow" @refetch="fetchData" /> -->
        
        <hb-modal v-model="showDelete" show-help-link v-if="showDelete" size="medium" :title="!usage.length? 'Delete Rent' : 'Cannot Delete Rent'" @close="closeWindow" confirmation>
            <template v-slot:content>
                
                <div class="py-4 px-6" v-if="!usage.length">
                   Are you sure you want to delete {{selected.name}}?
                   <br /><br />
                   This action cannot be undone.
                </div>
                <div class="py-4 px-6" v-else>
                   <strong>{{selected.name}} is currently in use in one or more processes so it cannot be deleted.</strong>
                   <br /><br />
                   <p>To delete this rent, first remove it from the following processes.</p>
                   
                    <ul>
                        <li v-for="(u, i) in usage" :key="i">{{ u.process | process_type }}: {{ u.name }}</li>
                    </ul>
                </div>
                
            </template>
            <template v-slot:actions>
                <hb-btn color="destructive" :disabled="!!usage.length" @click="deleteConfirm">Delete</hb-btn>
                <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
    import Modal from '../../assets/Modal.vue';
    import EditProduct from '../EditProduct.vue';
    import Status from '../../includes/Messages.vue';
    import settingsTabMixin from '../../../mixins/settingsTabMixin.js';
    
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';
    import { mapGetters } from 'vuex';


    export default {
        name: "RentIndex",
        mixins:[settingsTabMixin],
        props: {
            level : {
                type: String,
                default: ''
            },
            facilityID: {
                type : String,
                default: ''
            }
        },
        data() {
            return {
                rentList:[],
                selected: {},
                showAdd: false,
                showEdit: false,
                usage: [],
                showDelete: false,
                destroyComponent : true,
                property_id: '',
                headers: [
                    { text: "Name", value: "name" },
                    { text: "Taxable", value: "taxable" },
                    { text: "Prorate In", value: "in" },
                    { text: "Prorate Out", value: "out" },
                    { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                ],
            }
        },
        components:{
            Modal,
            EditProduct,
            Status,
            Loader
        },
        created(){
            if (this.level === 'Corporate' || this.$props.facilityID) {
                this.fetchData();
            }

        },
        computed:{
            ...mapGetters({
                facilityList: 'propertiesStore/filtered'
            }),
        },
        watch: {
            property_id(p) {
                this.fetchData();
            }
        },
        filters:{
            formatPrice(val){
                if(!val) return '-';
                return "$" + val;
            },
            process_type(process){
                if(process === 'delinquency') return "Delinquency Process";
                if(process === 'template') return "Lease Template"; 
                if(process === 'rent') return "Rent"; 
            }
        },
        methods:{
            initializeData(){
                this.rentList = [];
            },
            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.selected = {};
                setTimeout(() => {
                    this.destroyComponent = false;
                }, 200);
            },
            fetchData(){
                let path = this.facilityID || this.level === 'Property' ? api.PROPERTIES + (this.facilityID || this.property_id) + '/products?type=rent' : (api.PRODUCTS.substring(0, api.PRODUCTS.length - 1) + '?search=rent')
                api.get(this, path).then(r => {
                    this.rentList = r.products;
                }).catch(err => {
                    if (err === 'Not authorized') {
                        this.rentList = [];
                    }
                });
            },
            addItem(){
                this.destroyComponent = true;
                this.showAdd = true;
            },
            editItem(c){
                this.selected = c;
                this.destroyComponent = true;
                this.showEdit = true;
                this.showAdd = true;
            },
             async deleteItem(c){

                this.selected = c;
                let response = await api.get(this, api.PRODUCTS + `${this.selected.id}/usage`); 
                this.usage = response.usage;
                this.showDelete = true;
            },
            async deleteConfirm(){
                if(this.level === 'Property') {
                  await api.delete(this, api.PROPERTIES, `${this.facilityID || this.property_id}/products/${this.selected.id}`);
                } else {
                  await api.delete(this, api.INSURANCE, this.selected.id);
                }
                
                this.showDelete = false;
                this.fetchData();
                this.selected = {};
            },
            showSuccessStatus(name, isUpdated = false){
                let msg = isUpdated ? ('Rent "' + name + '" Updated') : ('New Rent "' + name + '" Created');
                this.successSet(this.$options.name, msg);
            }
        }
    }
</script>

<style scoped>
    .add-template-btn{
        font-size: 15px;
        text-decoration: none;
    }
    .add-template-btn:focus{
        border: none;
    }
    .ins-templete-table {
        background: #FFFFFF;
        box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
        border-radius: 4px;
    }
    .ins-templete-table .table-head{
        background: #FFFFFF;
        box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25);
    }
    .ins-templete-table .table-head strong{
        font-weight: 500;
        font-size: 15px;
        color: #474F5A;
    }
    .ins-templete-table .table-row:hover:not(:first-child){
        /* background: #F9FAFB; */
        cursor: inherit;
    }
    .ins-templete-table .table-row:first-child {
        cursor: inherit;
        border-bottom: 1px solid rgba(0, 0, 0, 0.001);
    }
    .ins-templete-table .table-row .table-cell{
        border-bottom: 1px solid #dce8ef;
    }
    .light-text{
        color: #637381;
    }
    .key-heading{
        font-size: 15px;
        color: #000;
    }
</style>

<style>
    .subdued {
        color: #a5adb4;
        line-height: 18px;
    }
</style>

